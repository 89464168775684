import React, { useState, useEffect, useContext } from "react";
import { CheckboxContext } from "Pages/Statics/LesOpportunite/Context/CheckboxProvider";
import CurrencyInput from "Components/Forms/CurrencyInput/CurrencyInput";
import Vector from "assets/svg/Vector";
import Undo from "assets/svg/FiltreSvg";


export default function FiltreModalites(props) {
    const { checkBoxValues, setCheckboxes } = useContext(CheckboxContext);
    const [isActive, setActive] = useState({})
    const [data, setData] = useState([])
    const [salary, setSalary] = useState(null);
    const [TJM, setTJM] = useState(null);

    function handleData(e, type) {
        let filterSelected = checkBoxValues.checkboxes[type]
        if (type === "jtm") {
            const timeoutId = setTimeout(() => {
                filterSelected = parseInt(e.target.value)
                let newValue = filterSelected !== 0 ? filterSelected : null
                setTJM(newValue)
                setCheckboxes(prevData => ({
                    ...prevData,
                    [type]: newValue
                }))
            }, 200);
            return () => {
                clearTimeout(timeoutId);
            };
        }

        if (type === "salaire") {
            const timeoutId = setTimeout(() => {
                filterSelected = parseInt(e.target.value)
                let newValue = filterSelected !== 0 ? filterSelected : null
                setSalary(newValue)
                setCheckboxes(prevData => ({
                    ...prevData,
                    [type]: newValue
                }))
            }, 200);
            return () => {
                clearTimeout(timeoutId);
            };
        }

        if (e.target.checked) {
            filterSelected.push(+e.target.value)
            setCheckboxes(prevData => ({
                ...prevData,
                [type]: filterSelected
            }))
        } else {
            let newFilter = filterSelected.filter(t => t !== +e.target.value)
            setCheckboxes(prevData => ({
                ...prevData,
                [type]: newFilter
            }))
        }
    }

    function handleRemote(e) {
        if (e.target.checked) {
            checkBoxValues.checkboxes.remote.push(e.target.value)
            setCheckboxes(prevData => ({
                ...prevData,
                remote: checkBoxValues.checkboxes.remote
            }))
        } else {
            let newFilter = checkBoxValues.checkboxes.remote.filter(t => t !== e.target.value)
            setCheckboxes(prevData => ({
                ...prevData,
                remote: newFilter
            }))
        }
    }
    function handleComptable(e) {
        if (e.target.checked) {
            checkBoxValues.checkboxes.comptable.push(e.target.value)
            setCheckboxes(prevData => ({
                ...prevData,
                comptable: checkBoxValues.checkboxes.comptable
            }))
        } else {
            let newFilter = checkBoxValues.checkboxes.comptable.filter(t => t !== e.target.value)
            setCheckboxes(prevData => ({
                ...prevData,
                comptable: newFilter
            }))
        }
    }

    function handleWorkTime(e) {
        if (e.target.checked) {
            checkBoxValues.checkboxes.workTime.push(e.target.value)
            setCheckboxes(prevData => ({
                ...prevData,
                workTime: checkBoxValues.checkboxes.workTime
            }))
        } else {
            let newFilter = checkBoxValues.checkboxes.workTime.filter(t => t !== e.target.value)
            setCheckboxes(prevData => ({
                ...prevData,
                workTime: newFilter
            }))
        }
    }

    const resetData = (e) => {
        if (e == 'jtm') {
            setTJM(0)
        } else {
            setSalary(0)
        }

        setCheckboxes(prevData => ({
            ...prevData,
            [e]: null
        }))
    }

    useEffect(() => {
        props.onField({ data: data, type: "modalite" })
    }, [data]);

    return (
        <main className="py-10 space-y-6 ">

            <h6 className="font-medium underline">Modalités</h6>

            {/* <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ comptable: !isActive.comptable })} >
                    <p>Expert comptable / Commisaire au compte</p>
                    <div id="plus1">
                        <Vector classProps={isActive.comptable ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.comptable ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        {["Expert comptable", "Commisaire au compte"].map((e, key) => (
                            <label className="flex gap-3.5 mt-3" key={e} >
                                <input onChange={(e) => handleComptable(e)}
                                    type="checkbox"
                                    className="border-8"
                                    value={e}
                                    checked={checkBoxValues.checkboxes.comptable}
                                />
                                {e}
                            </label>
                        ))}
                    </div>
                </article>
            </section> */}

            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ Disponibilite: !isActive.Disponibilite })} >
                    <p>Disponibilité</p>
                    <div id="plus1">
                        <Vector classProps={isActive.Disponibilite ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.Disponibilite ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        {props.field.disponibilite && props.field.disponibilite.map((e, key) => (
                            <label className="flex gap-3.5 mt-3" key={e.id} >
                                <input onChange={(e) => handleData(e, "disponibilite")}
                                    type="checkbox"
                                    className="border-8"
                                    value={e.id}
                                    checked={checkBoxValues.checkboxes.disponibilite.includes(e.id)}
                                />
                                {e.name}
                            </label>
                        ))}
                    </div>
                </article>
            </section>


            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ remote: !isActive.remote })} >
                    <p>Jour ouvré</p>
                    <div id="plus1">
                        <Vector classProps={isActive.remote ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.remote ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        {["Lundi", "Mardi", "Mercredi","Jeudi","Vendredi","Samedi","Dimanche"].map((e, key) => (
                            <label className="flex gap-3.5 mt-3" key={e} >
                                <input onChange={(e) => handleRemote(e)}
                                    type="checkbox"
                                    className="border-8"
                                    value={e}
                                    checked={checkBoxValues.checkboxes.remote.includes(e)}
                                />
                                {e}
                            </label>
                        ))}
                    </div>
                </article>
            </section>

            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ Localisation: !isActive.Localisation })} >
                    <p>Localisation</p>
                    <div id="plus1">
                        <Vector classProps={isActive.Localisation ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.Localisation ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        {props.field.localisation && props.field.localisation.map((e, key) => (
                            <label className="flex gap-3.5 mt-3" key={key} >
                                <input onChange={(e) => handleData(e, "localisation")}
                                    type="checkbox"
                                    className="border-8"
                                    value={e.code}
                                    checked={checkBoxValues.checkboxes.localisation.includes(+e.code)}
                                />
                                {e.nom}
                            </label>
                        ))}
                    </div>
                </article>
            </section>

            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ Remuneration: !isActive.Remuneration })} >
                    <p>Prétentions</p>
                    <div id="plus1">
                        <Vector classProps={isActive.Remuneration ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.Remuneration ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        <section className={`min-h-16`}>
                            <div className="flex flex-col text-left mt-6 gap-6">
                                <div>
                                    <p className="mb-2"> Salaire annuel K€</p>
                                    <CurrencyInput icon={"€"} onChange={(e) => handleData(e, "salaire")} />
                                </div>
                                <div>
                                    <p className="mb-2"> TJM</p>
                                    <CurrencyInput icon={"€"} onChange={(e) => handleData(e, "jtm")} />
                                </div>
                                <div>
                                    <p className="mb-2"> Taux horaire</p>
                                    <CurrencyInput icon={"€"} onChange={(e) => handleData(e, "taux_horaire")} />
                                </div>
                            </div>
                        </section>
                    </div>
                </article>
            </section>

            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ remote: !isActive.remote })} >
                    <p>Présentiel / télétravail</p>
                    <div id="plus1">
                        <Vector classProps={isActive.remote ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.remote ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        {["Présentiel", "Hybride", "Télétravail"].map((e, key) => (
                            <label className="flex gap-3.5 mt-3" key={e} >
                                <input onChange={(e) => handleRemote(e)}
                                    type="checkbox"
                                    className="border-8"
                                    value={e}
                                    checked={checkBoxValues.checkboxes.remote.includes(e)}
                                />
                                {e}
                            </label>
                        ))}
                    </div>
                </article>
            </section>
            <section className="">
                <div className="flex items-center justify-between"
                    onClick={() => setActive({ workTime: !isActive.workTime })} >
                    <p>Temps plein / partiel / à l'heure</p>
                    <div id="plus1">
                        <Vector classProps={isActive.workTime ? "rotate-180 transition duration-700" : "transition duration-700"} alt="+" />
                    </div>
                </div>
                <article className={`${isActive.workTime ? "animation-box-visible" : "animation-box-hidden"} `}>
                    <div className="animation-content ">
                        {["Temps plein", "Temps partiel", "À l'heure"].map((e, key) => (
                            <label className="flex gap-3.5 mt-3" key={e} >
                                <input onChange={(e) => handleWorkTime(e)}
                                    type="checkbox"
                                    className="border-8"
                                    value={e}
                                    checked={checkBoxValues.checkboxes.workTime.includes(e)}
                                />
                                {e}
                            </label>
                        ))}
                    </div>
                </article>
            </section>
        </main>
    );
}
